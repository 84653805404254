import React from 'react';
import { PATH_APP } from 'src/routes/paths';
import { MIcon } from 'src/theme';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HowToRegIcon from '@material-ui/icons/HowToReg';
// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  dashboard: <MIcon src={path('ic_dashboard')} />,
  page: <MIcon src={path('ic_page')} />
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'app',
        href: PATH_APP.general.dashboard
      }
    ]
  },
  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'Certificate',
    items: [
      {
        title: 'Bulk Upload',
        href: PATH_APP.app.bulkUpload,
        icon: ICONS.page
      },
      {
        title: 'Upload Certificate',
        href: PATH_APP.app.upload,
        icon: ICONS.page
      },
      {
        title: 'Verify Certificate',
        href: PATH_APP.app.verify,
        icon: <CheckCircleOutlineIcon />
      },
      {
        title: 'Beneficiaries',
        href: PATH_APP.app.beneficiaries,
        icon: <HowToRegIcon />
      }
    ]
  },
  {
    subheader: 'Admin',
    items: [
      {
        title: 'Issuers',
        href: PATH_APP.admin.issuers,
        icon: <HowToRegIcon />
      }
    ]
  }
];

export default navConfig;
