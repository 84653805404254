import API from '../../../constants/api';
import axios from 'axios';
import {
  saveUser,
  saveUserToken,
  saveUserPermissions
} from '../../../utils/sessionManager';

// const access_token = getUserToken();

// const _token = 'jwt-token';

export function verifyToken(token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API.AUTH}?access_token=${token}`)
      .then((res) => {
        if (res.data && res.data.user) {
          saveUser(res.data.user);
          saveUserToken(token);
          saveUserPermissions(res.data.permissions);
          resolve({
            is_approved: res.data.user.is_approved,
            sucess: true,
            status: 200
          });
        }
        resolve({
          success: false,
          status: 500
        });
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}

export const addIssuer = async (contract, payload) => {
  console.log('contract', contract);
  const { ipfsHash, name, address } = payload;
  try {
    const res = await contract.addIssuer(address, name, ipfsHash);
    console.log(res);
    const rest = await res.wait();
    return rest;
  } catch (err) {
    console.log(err);
    return err;
    //throw err;
  }
};

export const getAllIssuer = async (contract) => {
  try {
    const len = await contract.getTotalIssuers();
    const res = [];
    for (let index = 1; index < len; index++) {
      res.push(await contract.getIssuer(index));
    }
    return res;
  } catch (err) {
    console.log(err);
  }
};

// export function login(payload) {
//   return new Promise((resolve, reject) => {
//     axios
//       .post(API.AUTH, payload)
//       .then((res) => {
//         saveUserToken(res.data.accessToken);
//         saveUser(res.data.user);
//         saveUserRoles(res.data.user.roles);
//         resolve(res.data.user);
//       })
//       .catch((err) => {
//         reject(err.response.data);
//       });
//   });
// }

// export function listUsers() {
//   return new Promise((resolve, reject) => {
//     axios
//       .get(API.USERS, { headers: { Authorization: `${_token}` } })
//       .then((res) => {
//         resolve(res.data);
//       })
//       .catch((e) => reject(e.response.data));
//   });
// }

// export function addUser(payload) {
//   payload.geo_location = {
//     coordinates: [12.12, 13.13]
//   };
//   if (!payload.gender) payload.gender = 'O';
//   return new Promise((resolve, reject) => {
//     axios
//       .post(API.USERS, payload, { headers: { access_token } })
//       .then((res) => {
//         resolve(res.data);
//       })
//       .catch((err) => {
//         reject(err.response.data);
//       });
//   });
// }

// export async function getUserById(userId) {
//   const res = await axios({
//     url: `${API.USERS}/${userId}`,
//     method: 'get',
//     headers: {
//       access_token
//     }
//   });
//   return res.data;
// }

// export async function updateUser(userId, data) {
//   const res = await axios({
//     url: `${API.USERS}/${userId}`,
//     method: 'post',
//     headers: {
//       access_token
//     },
//     data
//   });
//   return res;
// }

// export async function changeUserStatus(userId, status) {
//   const res = await axios({
//     url: `${API.USERS}/${userId}/status`,
//     method: 'patch',
//     headers: {
//       access_token
//     },
//     data: { status }
//   });
//   return res.data;
// }

// export function forgotPassword(payload) {
//   return new Promise((resolve, reject) => {
//     axios
//       .post(`${API.USERS}/forgot_password`, payload)
//       .then((res) => {
//         resolve(res.data);
//       })
//       .catch((err) => {
//         reject(err.response.data);
//       });
//   });
// }

// export function verifyToken(payload) {
//   return new Promise((resolve, reject) => {
//     axios
//       .get(`${API.USERS}/password_reset/${payload.token}`)
//       .then((res) => {
//         resolve(res.data);
//       })
//       .catch((err) => {
//         reject(err.response.data);
//       });
//   });
// }

// export function resetPassword(id, payload) {
//   return new Promise((resolve, reject) => {
//     axios
//       .post(`${API.USERS}/${id}/reset_password`, payload)
//       .then((res) => {
//         resolve(res.data);
//       })
//       .catch((err) => {
//         reject(err.response.data);
//       });
//   });
// }

// export function changePassword(id, payload) {
//   return new Promise((resolve, reject) => {
//     axios
//       .post(`${API.USERS}/${id}/change_password`, payload)
//       .then((res) => {
//         resolve(res.data);
//       })
//       .catch((err) => {
//         reject(err.response.data);
//       });
//   });
// }
