import React, { createContext, useReducer } from 'react';
import userReduce from './reducers';
import * as Service from './services';
import API from 'src/constants/api';
import axios from 'axios';
import { getUserToken } from 'src/utils/sessionManager';
const access_token = getUserToken();
const ISSUERS = API.ISSUERS;
const USERS = API.USERS;

const initialState = {
  user_info: {},
  list: [],
  pagination: { limit: 10, start: 0, total: 0, currentPage: 1, totalPages: 0 }
};

export const UserContext = createContext(initialState);
export const UserContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReduce, initialState);

  // function userLogin(payload) {
  //   return Service.login(payload);
  // }
  async function verifyToken(token) {
    return new Promise((resolve, reject) => {
      Service.verifyToken(token)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async function addIssuer(contract, payload) {
    return await Service.addIssuer(contract, payload);
  }

  async function addIssuerAndApprove(contract, payload) {
    try {
      await Service.addIssuer(contract, payload);
      await axios.post(
        ISSUERS + `/${payload.address}/approve`,
        {},
        {
          headers: { access_token: access_token }
        }
      );
      return 'Issuer registered';
    } catch (err) {
      return err;
    }
  }
  async function addUserBackend(payload) {
    const { file, userDetails } = payload;
    console.log(JSON.stringify(userDetails));
    if (file == null) {
      return 'no identify file selected';
    }
    try {
      const formData = new FormData();
      formData.append('name', userDetails['name']);
      formData.append('wallet_address', userDetails['wallet_address']);
      formData.append('phone', userDetails['phone']);
      formData.append('email', userDetails['email']);
      formData.append('address', userDetails['homeAddress']);
      formData.append('docs', file);
      const config = {
        headers: { access_token: access_token }
      };
      axios
        .post(ISSUERS, formData, config)
        .then((res) => {
          console.log(res);
          return 'Issuer added successfully!';
        })
        .catch((err) => {});
    } catch (err) {
      console.log(err);
      if (err.data) {
        let arr = err.data.message.split(':');
        return arr[1];
      } else if (err.message) {
        let arr = err.message.split(':');
        return arr[1];
      } else {
        return 'Could not complete transaction';
      }
    }
  }

  async function getAllIssuer(contract, payload) {
    return await Service.getAllIssuer(contract, payload);
  }

  return (
    <UserContext.Provider
      value={{
        list: state.list,
        verifyToken,
        addIssuerAndApprove,
        addIssuer,
        getAllIssuer,
        addUserBackend,
        dispatch
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
