import axios from 'axios';
import API from 'src/constants/api';
import * as FileUtils from 'src/utils/fileUtils';
import * as EtherUtils from 'src/utils/ethersUtils';
import { getUserToken } from 'src/utils/sessionManager';
const access_token = getUserToken();

const DOCUMENT = API.DOCUMENTS;
const BENEFICIARY = API.BENEFICIARY;

export const uploadBulk = async (contract, payload = {}) => {
  try {
    const { hashes, expDates, meta_datas } = payload;
    const res = await contract.uploadCertificateBulk(
      hashes,
      expDates,
      meta_datas
    );
    return 'All Certificate added to blockchain';
  } catch (err) {
    console.log(err);
    return 'Failed to add certificate';
  }
};
export const verifyOne = async (contract, payload) => {
  const hash = payload;
  try {
    const res = await contract.getCertificate(hash);
    return res;
  } catch (err) {
    throw err;
  }
};

export const issuers = async (contract, payload) => {
  const hash = payload;
  try {
    const res = await contract.Issuers(hash);
    return res;
  } catch (err) {
    throw err;
  }
};

export const checkCertificate = async (contract, payload) => {
  const hash = payload;
  try {
    const res = await contract.checkCertificate(hash);
    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const uploadOne = async (contract, payload = {}) => {
  const { expiryDate, hash, metaHash } = payload;
  try {
    await axios.post(BENEFICIARY + `/register/${hash}`);
  } catch {
    return 'Certificate failed to be added in blockchain';
  }
  let keccakHash = await EtherUtils.textToBytes32(hash);
  const exists = await contract.checkCertificate(keccakHash);
  if (exists) {
    return 'Certificate already exists';
  }
  try {
    let expSolDate;
    expSolDate = Math.round(expiryDate / 1000);
    const res = await contract.uploadCertificate(
      keccakHash,
      expSolDate,
      metaHash
    );
    const rest = await res.wait();
    return 'Certificate added in blockchain';
  } catch (err) {
    return 'Failed to add blockchain';
  }
};

export const uploadOneAndIpfs = async (contract, payload = {}) => {
  const { expiryDate, hash, file, metadata } = payload;
  try {
    let expSolDate;
    let ipfsHash = await FileUtils.getIpfsHash(file);
    let keccakHash = await EtherUtils.textToBytes32(ipfsHash);
    let exists = await contract.checkCertificate(keccakHash);
    if (exists) {
      return 'Document already exists';
    }
    expSolDate = Math.round(expiryDate / 1000);
    const formData = new FormData();
    formData.append('name', file.name);
    formData.append('doc', file);
    formData.append('meta_data', JSON.stringify(metadata));
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        access_token
      }
    };
    const rest = await axios.post(DOCUMENT, formData, config);
    console.log(rest);
    const res = (
      await contract.uploadCertificate(hash, expSolDate, rest.data.meta_data)
    ).wait();
    return 'document uploaded';
  } catch (err) {
    throw err;
  }
};

export const getTransaction = async (contract, payload = '') => {
  return await contract.waitForTransaction(payload);
};
