import React from 'react';
import { ThemeConfig } from './theme';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import routes, { renderRoutes } from 'src/routes';
import ScrollToTop from 'src/global/ScrollToTop';
import { SnackbarProvider } from 'notistack';
import { UserContextProvider } from './modules/user/core/context';
import { AppContextProvider } from './modules/app/core/context';
import { WalletContextProvider } from './modules/certificate/core/AppSettingsContext';

// import LoadingScreen from 'src/global/LoadingScreen';

// ----------------------------------------------------------------------

const history = createBrowserHistory();

function App() {
  return (
    <div>
      <WalletContextProvider>
        <AppContextProvider>
          <SnackbarProvider autoHideDuration={2000}>
            <ThemeConfig>
              <UserContextProvider>
                <Router history={history}>
                  <ScrollToTop />
                  {renderRoutes(routes)}
                </Router>
              </UserContextProvider>
            </ThemeConfig>
          </SnackbarProvider>
        </AppContextProvider>
      </WalletContextProvider>
    </div>
  );
}

export default App;
