// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  auth: '/auth',
  dashboard: '/',
  app: '/app',
  admin: '/admin',
  error: '/error'
};

export const PATH_HOME = {
  dashboard: ROOTS.app
};

export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, '/login'),
    signup: path(ROOTS.auth, '/signup'),
    waitForApprove: path(ROOTS.auth, '/waitforapprove'),
    passport: path(ROOTS.auth, '/passport-control'),
    loginUnprotected: path(ROOTS.auth, '/login-unprotected'),
    resetPassword: path(ROOTS.auth, '/reset-password'),
    verify: path(ROOTS.auth, '/verify')
  },
  comingSoon: '/coming-soon'
};

export const PATH_APP = {
  root: ROOTS.dashboard,
  general: {
    root: path(ROOTS.dashboard, 'dashboard/app'),
    dashboard: path(ROOTS.dashboard, '')
    //analytics: path(ROOTS.dashboard, 'dashboard/analytics')
  },
  app: {
    upload: path(ROOTS.app, '/upload-certificate'),
    bulkUpload: path(ROOTS.app, '/bulk-upload'),
    beneficiaries: path(ROOTS.app, '/benefeciaries'),
    verify: path(ROOTS.app, '/verify-certificate'),
    verified: path(ROOTS.app, '/verified')
  },
  admin: {
    issuers: path(ROOTS.admin, '/issuers'),
    issuersDetails: path(ROOTS.admin, '/issuers/:id'),
    add: path(ROOTS.admin, '/issuers/add')
  },
  fallback: {
    noMetamask: path(ROOTS.error, '/no-metamask')
  }
};
